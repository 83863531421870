// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to instadrinkpro@gmail.com.

// ----------------------------------------------------------------------

const es = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hola`,
    description: `¿Necesitas ayuda? \n Por favor, consulta nuestra documentación.`,
    documentation: `documentación`,
  },
  app: 'Aplicación',
  user: 'Usuario',
  list: 'Lista',
  edit: 'Editar',
  shop: 'Tienda',
  blog: 'Blog',
  post: 'Publicación',
  mail: 'Correo',
  chat: 'Chat',
  cards: 'Tarjetas',
  posts: 'Publicaciones',
  create: 'Crear',
  kanban: 'Kanban',
  general: 'general',
  banking: 'Banca',
  booking: 'Reserva',
  profile: 'Perfil',
  account: 'Cuenta',
  product: 'Producto',
  invoice: 'Factura',
  details: 'Detalles',
  checkout: 'Pagar',
  calendar: 'Calendario',
  analytics: 'Analítica',
  ecommerce: 'Comercio electrónico',
  management: 'Gestión',
  menu_level_1: 'Menú nivel 1',
  menu_level_2: 'Menú nivel 2',
  menu_level_3: 'Menú nivel 3',
  item_disabled: 'Elemento deshabilitado',
  item_label: 'Etiqueta del elemento',
  item_caption: 'Descripción del elemento',
  description: 'Descripción',
  other_cases: 'Otros casos',
  item_by_roles: 'Elemento por roles',
  only_admin_can_see_this_item: 'Solo el administrador puede ver este elemento',
  create_event: 'Crear un evento',
  label_Rows_Per_Page: 'Filas por página',
  created_At: 'Creado el',
  location: 'Ubicación',
  title: 'Título',
  events: 'Eventos',
  archived: 'Archivado',
  canceled: 'Cancelado',
  Start_Date: 'Fecha de inicio del evento',
  End_Date: 'Fecha de finalización del evento',
  organisation: 'Organización',
  organisations: 'Organizaciones',
  published_events: 'Eventos Publicados',
  Add_event: 'Agregar Evento',
  event_name: 'Nombre del Evento',
  summary: 'Resumen',
  cancel: 'Cancelar',
  add: 'Agregar',
  view_event: 'Ver Evento',
  event_title: 'Título del Evento',
  cover: 'Portada',
  cover_image: 'Imagen de Portada',
  category: 'Categoría',
  sub_category: 'Subcategoría',
  status: 'Estado',
  listed: 'Evento Público',
  listed_tooltip: "Cuando está activado, su evento será visible públicamente y cualquiera podrá buscarlo en el listado de eventos",
  online_event: 'Evento en Línea',
  tags: 'Etiquetas',
  address: 'Dirección',
  city: 'Ciudad',
  capacity: 'Capacidad',
  save: 'Guardar',
  Start_Time: 'Hora de Inicio del evento',
  End_Time: 'Hora de Finalización del evento',
  timezone: 'Zona Horaria',
  Save_Changes: 'Guardar Cambios',
  Quantity: 'Cantidad',
  Initial_Quantity: 'Cantidad Disponible',
  Max_Quantity: 'Máximo por Pedido',
  price: 'Precio',
  free: 'Gratis',
  Sell_Time_Limit: 'Límite de Venta',
  delete: 'Eliminar',
  Edit: 'Editar',
  Add_Ticket: 'Agregar Tiquete',
  Sell_limit: 'Límite de Venta',
  start: 'Comienzo',
  end: 'Fin',
  Access_Time_Limit: 'Hora de acceso al evento',
  Price_Quantity: 'Precio y Cantidad',
  Access_limit: 'Límite de Acceso',
  name: 'Nombre',
  Your_event_is_already_published_on_this_link: 'Tu evento ya está publicado en este enlace',
  archive: 'Archivar',
  Event_Dashboard: 'Panel de Control del Evento',
  Preview_Event: 'Vista Previa del Evento',
  publish: 'Publicar',
  Email_Message: 'Texto del correo electrónico enviado a los participantes de su evento',
  Ticket_Message: 'Mensaje del Tiquete',
  include: 'Incluir',
  required: 'Requerido',
  Gender: 'Género',
  Birthday: 'Fecha de Nacimiento',
  HomePhone: 'Teléfono de Casa',
  yourEventIs: 'Tu evento es',
  date: 'Fecha',
  events_catering: 'Eventos y catering',
  events_catering_description:
    'ID es una plataforma que permite a sus clientes comprar entradas para asistir a sus eventos, así como pedir su comida y bebida digitalmente en su establecimiento.',
  create_digital_exp: 'Cree su experiencia digital',
  create_digital_exp_description:
    'Los clientes interactúan con su marca, a su manera; usted controla su presencia digital para maximizar los ingresos y la rentabilidad.',
  digital_ticketing: 'VENTA DE ENTRADAS DIGITAL',
  digital_ticketing_title: 'Añada sus eventos y venda entradas en nuestra plataforma.',
  digital_ticketing_description:
    'Cada uno de tus clientes que desee participar puede hacer clic en un enlace o escanear un código QR en el folleto digital o físico de tu evento; esto les dirigirá a la plataforma ID donde podrán seleccionar y pagar su entrada.',
  more_questions: '¿Aún tiene preguntas?',
  more_questions_description: 'Describa su caso para recibir el asesoramiento más preciso.',
  who_are_we: {
    who: '¿',
    are: 'Quiénes',
    we: 'somos',
    work: 'Trabajamos juntos para',
    futur: 'un futuro mejor en eventos y la restauración',
  },
  what_is_id: {
    title: '¿Qué es ID?',
    description:
      'ID es una plataforma que permite a tus clientes comprar entradas para asistir a tus eventos, así como pedir su comida y bebida digitalmente en tu establecimiento.',
  },
  about: {
    vision:
      'Nuestra visión: ofrecer la plataforma perfecta para un futuro mejor en eventos y catering.',
    dream_team: 'EQUIPO DE SUEÑOS',
    dream_team_description:
      'ID le proporcionará asistencia si tiene algún problema. Nuestro equipo de asistencia responderá. Un buen equipo es la clave. En el plazo de un día, y también disponemos de documentación detallada.',
  },
  testimonials: {
    title: 'TESTIMONIOS',
    who: '¿A quién le gusta ',
    service: ' nuestro servicio?',
    description:
      'Nuestro objetivo es crear la plataforma perfecta que satisfaga a todos nuestros clientes de eventos y catering. Por eso trabajamos constantemente en nuestros servicios para mejorarlos cada día escuchando las valiosas sugerencias que nuestros usuarios comparten con nosotros.',
  },

  Min_Quantity: 'Mínimo por pedido',
  save_continue: 'Guardar y continuar',
  continue_later: 'No guardar y continuar más tarde',
  Link_to_buy_tickets_for_your_event: 'Enlace para comprar boletos para tu evento',
  back: 'Atrás',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Completa los detalles del evento para poder publicarlo',
  publish_the_event: 'Publicar el evento',
  view: 'Ver',
  ready_events: 'Eventos listos',
  drafted_events: 'Eventos borrador',
  archived_events: 'Eventos archivados',
  canceled_events: 'Eventos cancelados',
  no_events: 'Sin eventos',
  my_events: 'Mis eventos',
  total_sold: 'Los ingresos totales',
  sold_quantity: 'Número de entradas vendidas',
  event_link: 'Enlace del evento',
  sale_date: 'Fecha de venta',
  validated: 'Validado',
  confirmed: 'Confirmado',
  reimbursed: 'Reembolsado',
  About: 'Acerca de',
  Name: 'Nombre',
  promoters: 'PromoBoosteres',
  add_promoter: 'Agregar PromoBooster',
  choose_promoter: 'Elegir PromoBooster',
  remove: 'Eliminar',
  min_followers: 'Seguidores Mínimos',
  Dashboard: 'Tablero de Control',
  General: 'General',
  Preview: 'Vista Previa',
  required_information: 'Información Requerida',
  promo_code: 'Códigos de Promoción',
  overview: 'Resumen',
  preview_your_event: 'Vista Previa de tu Evento',
  Owner: 'Dueño',
  create_org: 'Crear una organización',
  Account: 'Cuenta',
  account_settings: 'Configuración de Cuenta',
  Allowed_format: 'Formatos permitidos',
  max_size: 'tamaño máximo de',
  billing: 'facturación',
  Email_Address: 'Dirección de Correo Electrónico',
  Phone_Number: 'Número de Teléfono',
  Country: 'País',
  State_Region: 'Estado/Región',
  Zip_Code: 'Código Postal',
  discount: 'Descuento',
  maximum_number_of_sold_tickets: 'Número máximo de boletos vendidos',
  Featured_tickets: 'Boletos Destacados',
  No_promo_code_available: 'No hay códigos de promoción disponibles',
  add_promo_code: 'Agregar código de promoción',
  This_code_is_already_used: '¡Este código ya está en uso!',
  discount_amount: 'Monto del Descuento',
  advanced_options: 'Opciones Avanzadas',
  all_tickets: 'Todos los boletos',
  specific_tickets: 'Boletos específicos',
  Legal_info: 'Información Legal',
  Followers: 'Seguidores',
  Total_events: 'Total de Eventos',
  Recommended: 'Recomendado',
  company_name: 'Nombre de la Compañía',
  company_number: 'Número de Compañía',
  Website: 'Sitio Web',
  Cover: 'Portada',
  add_organisation: 'Agregar organización',
  create_user: 'Crear usuario',
  users: 'usuarios',
  firstname: 'Nombre',
  lastname: 'Apellido',
  role: 'Rol',
  access: 'Acceso',
  events_permissions: 'Permisos de Eventos',
  organisations_permissions: 'Permisos de Organizaciones',
  Yes: 'Sí',
  No: 'No',
  Permissions: 'Permisos',
  Editing: 'Edición',
  Roles: 'Roles',
  Search: 'Buscar',
  Event_stats: 'Estadísticas del Evento',
  sales: 'ventas',
  Number_of_purchase: 'Número de compras',
  Link: 'Enlace',
  sold: 'vendido',
  available: 'disponible',
  Tickets_status: 'Estado de los Boletos',
  There_is_no_tickets_status_for_this_event: 'No hay estado de boletos para este evento',
  promoter: 'PromoBooster',
  invite_user: 'Invitar usuario',
  published: 'Publicado',
  ready: 'Listo',
  draft: 'Borrador',
  initial: 'inicial',
  upcoming: 'próximo',
  ongoing: 'en curso',
  past: 'pasado',
  Organisation: 'Organización',
  Bio: 'Biografía',
  Type: 'Tipo',
  Evenementiel: 'Eventos',
  Restaurant: 'Restaurante',
  Email: 'Correo electrónico',
  Rajoutez_une_image_de_couverture_pour_votre_événement:
    'Agrega una imagen de portada para tu evento',
  Upload_photo: 'Subir foto',
  Update_photo: 'Actualizar foto',
  organisation_users: 'Usuarios de la organización',
  Agent_liés_à_lorganisation: 'Agentes relacionados con la organización',
  Agents_en_attente_de_confirmation: 'Agentes en espera de confirmación',
  Instadrink_admin: 'Administrador de I.D',
  create_role: 'Crear un rol',
  Predefined_roles: 'Roles predefinidos',
  Custom_roles: 'Roles personalizados',
  Agents: 'Agentes',
  Organizer: 'Organizador',
  Bouncer: 'Bravucón',
  Ambassador: 'Embajador',
  promotion_groups: 'grupos de promoción',
  Dates: 'fechas',
  Tickets: 'Entradas',
  Information: 'Información',
  Messages: 'Mensajes',
  Promotion: 'Promoción',
  Participants: 'Participantes',
  Client: 'Cliente',
  description_placeholder:
    'Describa a sus futuros participantes la atmósfera excepcional que prevalecerá en su evento.',
  Tickets_collection: 'Colección de entradas',
  Stop_sell_time_is_at: 'La hora de cierre de ventas es a las',
  drag_and_drop: 'Arrastra y suelta para cambiar el orden',
  delete_ticket_confirmation: '¿Estás seguro de que quieres eliminar este boleto?',
  Access_Time_Thresholdt: 'Hora de finalización del acceso al evento',
  order: 'orden',
  Créez_les_tickets_pour_votre_événement: 'Crea entradas para tu evento',
  stop_sell_time_error_message:
    'La fecha de finalización debe ser posterior a la fecha de inicio y no debe superar la fecha de cierre de ventas',
  Reset: 'Restablecer',
  send_invitation: 'Enviar invitación',
  Tickets_quantity_error_message:
    'La cantidad total de boletos ha alcanzado la capacidad máxima del evento, que es',
  Write_something_awesome: 'Escribe algo increíble...',
  Code: 'Código',
  delete_promocode_confirm_message: '¿Estás seguro de que quieres eliminar este código?',
  Activate_promotion: 'Activar promoción',
  Desactivate_promotion: 'Desactivar promoción',
  Configure_RP_offer: 'Configurar la oferta de Promobooster',
  promotion_status: 'estado de promoción',
  TooltipTextForPromotionGroups:
    'En esta sección, puede crear y gestionar grupos de Personas de Recursos (PR) para sus eventos.',
  TooltipTextForEventLink: 'Publique su evento para obtener el enlace de su evento.',
  TooltipTextForPromotionGroupsForReferral:
    'En esta sección, encontrarás los grupos de Promobooster de los que eres miembro y podrás salir del grupo cuando lo desees.',
  confirm_delete_promoter: '¿Estás seguro de que deseas eliminar a este PromoBooster?',
  Preview_promoter_link: 'Vista previa del enlace del PromoBooster',
  Locked: 'Bloqueado',
  pending: 'Pendiente',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  Publish_my_event_now: 'Publicar mi evento ahora',
  publish_later: 'Publicar más tarde',
  Your_event_is_still_draft:
    'Tu evento aún es un borrador, completa la configuración para publicarlo',
  Are_you_sure_to_publish_the_event: '¿Estás seguro de publicar el evento?  ',
  pourcentage: 'porcentaje',
  Rate: 'Tasa',
  Message: 'Mensaje',
  submit: 'entregar',
  Create_group: 'crea un grupo',
  Groups: 'Grupos',
  Edit_group: 'Editar grupo',
  Search_by_Name: 'Buscar por nombre',
  Enter_group_name: 'Introduzca el nombre del grupo',
  No_promoters_found: 'No se encontraron PromoBoosteres',
  Add_group: 'Añadir grupo',
  Number_of_sales: 'Número de ventas',
  Turnover: 'Cifra de negocio',
  Number_of_clients: 'Numero de clientes',
  Promotion_requests: 'Solicitudes de promoción',
  Requests: 'Peticiones',
  Notifications: 'Notificaciones',
  Referral_requests: 'Solicitudes de PromoBooster',
  no_requests: 'No hay solicitudes',
  request_message: 'te envió una solicitud para invitarte a ser el PromoBooster de',
  Accept: 'Aceptar',
  Reject: 'Rechazar',
  Your_invitation_to: 'Tu invitación a',
  on: 'el',
  at: 'a las',
  is: 'està',
  promotion_rate: 'tasa de promoción',
  consigne: 'consigna',
  Cancel_request: 'cancelar petición',
  Send_request: 'Enviar petición',
  No_events: 'Encuentre un evento con el que le gustaría colaborar como PromoBooster',
  Search_for_new_events: 'Buscar nuevos eventos',
  Search_for_Events: 'Buscar eventos',
  Sales_stats: 'Estadísticas de ventas',
  Total_sales: 'Ventas totales',
  Total_reservation: 'Total reservations',
  Bienvenue_message: 'Bienvenido a I.D, elija su sector',
  second_bienvenu_message:
    'Bienvenido a I.D, seleccione su sector entre eventos y catering.',
  Continue: 'continuar',
  Event: 'Evento',
  orga_message: 'Elija la organización bajo la cual creará su evento',
  Skip: 'Pasar',
  All_steps_completed: 'Todos los pasos completados',
  you_have_finished: 'ha terminado',
  limit_sell_message: 'Ingrese una fecha límite y hora para la venta de boletos',
  stopSellDate: 'Dejar de vender entradas con fecha',
  status_tooltip:
    'Este campo de estado calcula automáticamente la fecha de inicio y la fecha de fin, y te proporciona el estado del evento',
  Step: 'Paso',
  correct_start_date: 'Corregir la fecha de inicio pasada',
  Finish: 'Finalizar',
  event_name_tooltip: '¿Cómo se llama tu evento?',
  event_summary_tooltip:
    'Dé un vistazo a las hermosas sorpresas que esperan a los futuros participantes en su evento.',
  event_description_tooltip:
    'En esta ocasión, describa en detalle el evento al que los futuros participantes tendrán la oportunidad de asistir.',
  event_cover_tooltip: 'Elija una foto para su evento.',
  organisation_tooltip: 'Elija el nombre de la organización que organizará su evento.',
  category_tooltip: 'Seleccione una categoría para su evento.',
  subCategory_tooltip: 'Elija una subcategoría para su evento.',
  Business: 'Negocios',
  Charity: 'Caridad',
  Causes: 'Causas',
  Community: 'Comunidad',
  Concerts: 'Conciertos',
  Music: 'Música',
  Conferences: 'Conferencias',
  Expos: 'Exposiciones',
  Fashion: 'Moda',
  Festivals: 'Festivales',
  Film: 'Cine',
  Media: 'Medios',
  Food: 'Comida',
  Drink: 'Bebida',
  Health: 'Salud',
  Hobbies: 'Aficiones',
  Nightlife: 'Vida nocturna',
  Other: 'Otros',
  Performing: 'Artes escénicas',
  arts: 'arte',
  Seasonal: 'Estacional',
  Holiday: 'Vacaciones',
  Sports: 'Deportes',
  Fitness: 'Fitness',
  Travel: 'Viaje',
  Outdoor: 'Aire libre',
  Career: 'Carrera',
  Design: 'Diseño',
  Educators: 'Educadores',
  Environment: 'Medio ambiente',
  Sustainability: 'Sostenibilidad',
  Finance: 'Finanzas',
  Investment: 'Inversión',
  Non: 'No',
  Profit: 'Lucrativo',
  NGOs: 'ONG',
  Real: 'Real',
  Estate: 'Inmobiliario',
  Sales: 'Ventas',
  Marketing: 'Marketing',
  Startups: 'Startups',
  Small: 'Pequeñas',
  Animal: 'Animal',
  Welfare: 'Bienestar',
  Art: 'Arte',
  Auctions: 'Subastas',
  Benefit: 'Beneficio',
  Walks: 'Caminatas',
  Disaster: 'Desastre',
  Relief: 'Alivio',
  Donor: 'Donante',
  Recognition: 'Reconocimiento',
  Educational: 'Educativas',
  Fundraising: 'Recaudación de fondos',
  Homeless: 'Personas sin hogar',
  shelters: 'Refugios',
  Human: 'Humano',
  Rights: 'Derechos',
  International: 'Internacional',
  Aid: 'Ayuda',
  Marches: 'Marchas',
  Poverty: 'Pobreza',
  Rallies: 'Manifestaciones',
  Demonstrations: 'Manifestaciones',
  Ethnic: 'Étnico',
  Fairs: 'Ferias',
  Farmers: 'Agricultores',
  markets: 'Mercados',
  Fetes: 'Fiestas',
  Fun: 'Diversión',
  runs: 'Carreras',
  Heritage: 'Herencia',
  Historic: 'Histórico',
  Language: 'Idioma',
  LGBTQ: 'LGBTQ',
  Nationality: 'Nacionalidad',
  Parades: 'Desfiles',
  Protests: 'Protestas',
  Religious: 'Religiosos',
  Renaissance: 'Renacimiento',
  Spiritual: 'Espiritual',
  Town: 'Pueblo',
  hall: 'salón',
  meetings: 'reuniones',
  Acoustic: 'Acústico',
  Americana: 'Americana',
  Bluegrass: 'Bluegrass',
  Blues: 'Blues',
  Jazz: 'Jazz',
  Classical: 'Clásica',
  Cultural: 'Cultural',
  Dance: 'Danza',
  EDM: 'Música electrónica',
  Electronic: 'Electrónica',
  Experimental: 'Experimental',
  Folk: 'Folk',
  Hip: 'Hip',
  Hop: 'Hop',
  Rap: 'Rap',
  Indie: 'Indie',
  Latin: 'Latina',
  Metal: 'Metal',
  Opera: 'Ópera',
  Pop: 'Pop',
  Psychedelic: 'Psicodélico',
  Reggae: 'Reggae',
  Rock: 'Rock',
  Singer: 'Cantante',
  Songwriter: 'Cantautor',
  Top: 'Top',
  World: 'Mundo',
  Colloquium: 'Coloquio',
  Conference: 'Conferencia',
  Congress: 'Congreso',
  Convention: 'Convención',
  Demonstration: 'Demostración',
  Exhibition: 'Exhibición',
  Forum: 'Foro',
  Meeting: 'Reunión',
  Presentation: 'Presentación',
  Round: 'Redonda',
  table: 'mesa',
  Seminar: 'Seminario',
  Symposium: 'Simposio',
  Workshop: 'Taller',
  Award: 'Premio',
  Ceremonies: 'Ceremonias',
  Screening: 'Proyección',
  Movie: 'Película',
  Premiere: 'Estreno',
  Television: 'Televisión',
  Video: 'Video',
  Game: 'Juego',
  Education: 'Educación',
  Industry: 'Industria',
  Science: 'Ciencia',
  Technology: 'Tecnología',
  Barbecue: 'Barbacoa',
  Breakfast: 'Desayuno',
  Brunch: 'Brunch',
  Buffet: 'Buffet',
  Cocktail: 'Cóctel',
  Mixology: 'Mixología',
  Culinary: 'Culinaria',
  workshops: 'talleres',
  Dessert: 'Postre',
  Pastry: 'Pastelería',
  Tastings: 'Degustaciones',
  Dinner: 'Cena',
  Farm: 'Granja',
  to: 'a',
  Table: 'mesa',
  Dining: 'Cena',
  Gourmet: 'Gourmet',
  Markets: 'Mercados',
  Lunch: 'Almuerzo',
  Picnic: 'Picnic',
  Wine: 'Vino',
  Beer: 'Cerveza',
  Adult: 'Adulto',
  Anime: 'Anime',
  Comics: 'Cómics',
  Book: 'Libro',
  Books: 'Libros',
  Collecting: 'Coleccionismo',
  Cooking: 'Cocina',
  DIY: 'Hazlo tú mismo',
  Crafts: 'Manualidades',
  Drawing: 'Dibujo',
  Painting: 'Pintura',
  Gaming: 'Juegos',
  Gardening: 'Jardinería',
  Home: 'Hogar',
  Improvement: 'Mejoras',
  Knitting: 'Tejido',
  Photography: 'Fotografía',
  Reading: 'Lectura',
  Sewing: 'Costura',
  Writing: 'Escritura',
  Bar: 'Bar',
  Crawls: 'Rastreo',
  Bingo: 'Bingo',
  Cabaret: 'Cabaret',
  Casino: 'Casino',
  Comedy: 'Comedia',
  Clubs: 'Clubes',
  Parties: 'Fiestas',
  Dancehall: 'Dancehall',
  Drinks: 'Bebidas',
  Karaoke: 'Karaoke',
  Nightclubs: 'Clubs nocturnos',
  Open: 'Abierto',
  Mic: 'Micrófono',
  Pubs: 'Pubs',
  Raves: 'Raves',
  Medical: 'Médico',
  Spa: 'Spa',
  Yoga: 'Yoga',
  Circus: 'Circo',
  Exhibitions: 'Exhibiciones',
  Magic: 'Magia',
  Musicals: 'Musicales',
  Plays: 'Obras de teatro',
  Theater: 'Teatro',
  Christmas: 'Navidad',
  Easter: 'Pascua',
  Halloween: 'Halloween',
  Hanukkah: 'Hanukkah',
  New: 'Nuevo',
  Year: 'Año',
  Eve: 'Víspera',
  Thanksgiving: 'Día de Acción de Gracias',
  Archery: 'Tiro con arco',
  Baseball: 'Béisbol',
  Basketball: 'Baloncesto',
  Camping: 'Campamento',
  Camps: 'Campamentos',
  Cars: 'Coches',
  Cheer: 'Porra',
  Climbing: 'Escalada',
  Cycling: 'Ciclismo',
  Diving: 'Buceo',
  Exercise: 'Ejercicio',
  Fishing: 'Pesca',
  Football: 'Fútbol',
  Golf: 'Golf',
  Hiking: 'Senderismo',
  Hockey: 'Hockey',
  Hunting: 'Caza',
  Jogging: 'Trote',
  Lacrosse: 'Lacrosse',
  Motorsports: 'Deportes de motor',
  Obstacles: 'Obstáculos',
  Parachuting: 'Paracaidismo',
  Rally: 'Rally',
  Rugby: 'Rugby',
  Running: 'Correr',
  Skating: 'Patinaje',
  Skiing: 'Esquí',
  Snow: 'Nieve',
  Snowboarding: 'Snowboard',
  Soccer: 'Fútbol',
  Softball: 'Softbol',
  Sport: 'Deporte',
  Surfing: 'Surf',
  Swimming: 'Natación',
  Water: 'Agua',
  Tennis: 'Tenis',
  Volleyball: 'Voleibol',
  Walking: 'Caminata',
  Weightlifting: 'Levantamiento de pesas',
  Windsurfing: 'Windsurf',
  Wrestling: 'Lucha',
  Cruise: 'Crucero',
  Expeditions: 'Expediciones',
  Museum: 'Museo',
  visit: 'visita',
  Road: 'Viajes',
  Trips: 'Viajes',
  tours: 'Tours',
  Rp_request: 'Te envié solicitud para ser el PromoBooster de su evento.',
  warning_message: 'Por favor presione "agregar" primero para guardar su boleto y continuar',
  correct_date_tooltip: 'La fecha de inicio seleccionada para su evento ya ha pasado.',
  step1:
    'En este paso, encontrará las estadísticas de ventas del evento y la lista de asistentes al evento.',
  step2: 'En este paso, puede editar los detalles del evento.',
  step3: 'En este paso, puede editar las fechas y horas del evento.',
  step4:
    'En este paso, puede agregar nuevos boletos para su evento o editar los boletos existentes.',
  step5: 'En este paso, puede publicar o archivar su evento.',
  step6:
    'En este paso, encontrará la vista del evento para clientes en la vista de escritorio o móvil.',
  step7: 'En este paso, encontrará una descripción general de los detalles de su evento.',
  step8: 'En este paso, puedes obtener información sobre el evento.',
  stepCodePromo: 'En este paso, puede agregar códigos promocionales para los boletos.',
  step10:
    'En este paso, puede gestionar los PromoBooster de su evento, crear un grupo de PromoBooster o enviar solicitudes para que el PromoBooster sea PromoBooster de su evento.',
  step11: 'En este paso, encontrará la lista de los asistentes.',
  total_sold_tooltip: 'Este es el precio total de venta para las entradas del evento.',
  sold_quantity_tooltip: 'Esta es la cantidad total de entradas vendidas para el evento.',
  link_tooltip: 'Este es el enlace del evento para comprar tus entradas.',
  table_tooltip: 'Aquí está la tabla donde encontrarás la lista de los asistentes a este evento.',
  create_event_button_tooltip: 'Haz clic aquí para crear un nuevo evento.',
  events_table_tooltip:
    'Esta es la tabla de eventos donde puedes encontrar los detalles de tus eventos.',
  You_have_no_organisations: 'No tienes organizaciones',
  alert_orga_title: 'Debes crear una organización para poder crear un evento después.',
  Configurate_here: 'Configura aquí',
  legal_verification: 'Verificación legal de tu cuenta',
  alert_stripe_configuration_title:
    'Por favor, completa tu información legal para activar tu cuenta y poder vender tus entradas.',
  Bank_balance: 'Saldo bancario',
  View_my_Stripe_account: 'Ver mi cuenta de Stripe',
  Your_balance: 'Tu saldo',
  Add_new_card: 'Agregar nueva tarjeta',
  Name_on_card: 'Nombre en la tarjeta',
  Card_number: 'Número de tarjeta',
  Expiration_date: 'Fecha de vencimiento',
  QRcode_tooltip: 'Haz clic aquí para personalizar y descargar el código QR de tu evento',
  File_Type: 'Tipo de archivo',
  Size: 'Tamaño',
  Color: 'Color',
  Background_Color: 'Color de fondo',
  Download_QR_Code: 'Descargar código QR',
  Download_Invoice: 'Descargar factura',
  show_qr_code: 'Mostrar código QR',
  Close: 'Cerrar',
  Customize_QR_Code: 'Personalizar código QR',
  no_users_yet: 'Aún no hay usuarios',
  plz_invite_users: 'Por favor, invite a usuarios',
  invite_organizer: 'Invitar al organizador',
  invite: 'invitar',
  invite_owners: 'invitar propietarios',
  register: 'Registrarse',
  password: 'Contraseña',
  confirm_password: 'Confirmar Contraseña',
  email_adress: 'Dirección de Correo Electrónico',
  division_agent: 'Agente',
  division_promoter: 'PromoBooster',
  iam_owner: 'Soy Propietario',
  iam_owner_desc: 'Administra tus organizaciones, restaurantes y eventos',
  iam_amb: 'Soy Embajador',
  iam_amb_desc: 'Promociona organizaciones y gana comisiones',
  iam_ref: 'Soy PromoBooster',
  iam_ref_desc: 'Refiere eventos y gana comisiones',
  start_free: 'Comenzar Gratis',
  no_credit_card: 'No se requiere tarjeta de crédito',
  have_account: '¿Ya tienes una cuenta?',
  by_register_1: 'Al registrarme, acepto los ',
  by_register_2: 'Términos de Servicio',
  by_register_3: ' y la ',
  by_register_4: 'Política de Privacidad de I.D.',
  manager_better_register: 'Administra eventos de manera más eficiente con I.D',
  referral: 'PromoBooster',
  first_name_required: 'Se requiere nombre',
  first_name_short: 'El nombre es demasiado corto - debe tener al menos 3 caracteres.',
  first_name_long: 'El nombre es demasiado largo - debe tener como máximo 50 caracteres.',
  first_name_alpha: 'El nombre solo puede contener caracteres alfabéticos.',
  last_name_required: 'Se requiere apellido',
  last_name_short: 'El apellido es demasiado corto - debe tener al menos 3 caracteres.',
  last_name_long: 'El apellido es demasiado largo - debe tener como máximo 50 caracteres.',
  last_name_alpha: 'El apellido solo puede contener caracteres alfabéticos.',
  email_invalid: 'El correo electrónico debe ser una dirección de correo válida',
  email_required: 'Se requiere correo electrónico',
  password_required: 'Se requiere contraseña',
  password_short: 'La contraseña es demasiado corta - debe tener al menos 8 caracteres.',
  password_alpha: 'La contraseña solo puede contener letras latinas.',
  password_uppercase: 'La contraseña debe contener al menos una letra mayúscula.',
  password_number: 'La contraseña debe contener un número.',
  confirm_password_match: 'Las contraseñas deben coincidir',
  confirm_password_required: 'Se requiere confirmar contraseña',
  division_required: 'Se requiere división',
  division_invalid: 'La división debe ser PromoBooster o Agente',
  role_required: 'Se requiere rol',
  role_invalid_promoter: 'Rol inválido para PromoBooster',
  role_invalid_agent: 'Rol inválido para Agente',
  login: 'Iniciar Sesión',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Idioma',
  creating_account: 'Creación de Cuenta en Progreso',
  creating_account_desc: 'Finalizando la Configuración de su Cuenta',
  creating_account_info:
    'Su cuenta está siendo configurada. Por favor, permanezca en esta página hasta que el proceso esté completo. ¡Agradecemos su paciencia!',
  accept_invitation_info:
    'Tenga en cuenta que, después de aceptar la invitación a continuación, recibirá un correo electrónico de verificación. Después de la verificación, podrá completar la configuración de su cuenta, incluidas las opciones para cambiar su nombre y el idioma preferido.',
  invitation_already_processed: "Esta invitación ya ha sido procesada. Por favor, consulte su panel de eventos para más información.",
  verify_email: 'Verifique Su Correo Electrónico',
  verify_email_desc: 'Cuenta Creada con Éxito',
  verify_email_info:
    '¡Felicidades por configurar su cuenta! Por favor, revise su bandeja de entrada para verificar su cuenta y obtener acceso completo.',
  wait_verify: 'Por favor, espere un momento mientras verificamos su correo electrónico.',
  wait_redirect: 'No cierre esta ventana hasta que haya sido redirigido al panel de control.',
  initialAccountHeader: 'Finaliza tu cuenta de I.D',
  initialAccountDescription:
    '¡Bienvenido de nuevo! Solo establece tu contraseña a continuación para finalizar la configuración de tu cuenta.',
  initialAccountButton: 'Completar configuración',
  processingAccountHeader: 'Configurando tu cuenta...',
  processingAccountDescription:
    'Por favor, espera un momento mientras confirmamos tus detalles y aseguramos tu cuenta.',
  processingAccountButton: 'Completando...',
  successAccountHeader: '¡Éxito! Cuenta lista',
  successAccountDescription:
    'Tu cuenta se ha configurado con éxito y estás iniciando sesión. Serás redirigido al tablero de mandos en breve.',
  successAccountButton: 'Redirigiendo...',
  errorAccountHeader: 'Algo salió mal',
  errorAccountDescription:
    'Hubo un problema con la configuración de tu cuenta. El enlace puede ser inválido o haber expirado, o el correo electrónico puede ser incorrecto.',
  errorAccountButton: 'Intentar de nuevo',
  linkexpiredAccountHeader: 'Revisa Tu Correo Electrónico',
  linkexpiredAccountDescription: "Hubo un problema con la configuración de tu cuenta. El enlace podría ser inválido o haber expirado, o la dirección de correo electrónico podría ser incorrecta.",
  verification_expired: "¡Fallo en la configuración de la cuenta! El enlace de verificación de tu cuenta ha expirado.",
  new_verification_link_sent: "Se ha enviado un nuevo enlace de verificación a tu dirección de correo electrónico.",
  login_welcome: 'Hola, Bienvenido',
  login_connect: 'Inicia sesión en I.D',
  login_type: 'Ingresa tus datos abajo.',
  login_no_account: '¿No tienes una cuenta?',
  login_register: 'Registrarse',
  owner_total_part:
    'Estas son las acciones del propietario para la venta de entradas para el evento.',
  my_share: 'Mi parte',
  Remember_password: 'Recordar contraseña',
  forgot_password: '¿Contraseña olvidada?',
  create_your_first_organization: 'Crea tu primera organización',
  create_organization_step_description:
    'Para empezar a gestionar tus eventos, crea tu primera organización. Puedes crear tantas organizaciones como quieras más adelante.',
  create_your_first_event: 'Crea tu primer evento',
  create_event_step_description:
    'Ahora que has creado tu primera organización, es hora de crear brevemente tu primer evento. Puedes editarlo en detalle más tarde.',
  congratulations: '¡Felicidades!',
  last_step_description: 'Ahora puedes ir a la lista de eventos y continuar creando tu evento.',
  create_your_first_restaurant: 'Crea tu primer restaurante',
  create_restaurant_step_description:
    'Para empezar a gestionar tus mesas, crea tu primer restaurante. Puedes crear tantos restaurantes como quieras más adelante.',
  create_restaurant_last_step_description:
    'Ahora puedes ir a la lista de restaurantes y continuar creando tus mesas.',
  Logout: 'Cerrar sesión',
  Settings: 'Configuración',
  create_store: 'Crear restaurante',
  add_category: 'Agregar categoría',
  select_store: 'Seleccionar un restaurante',
  add_new_product: 'Agregar nuevo producto',
  add_new_category: 'Agregar nueva categoría',
  products: 'Productos',
  categories: 'Categorías',
  unavailable: 'No disponible',
  composite: 'Compuesto',
  alcoholic: 'Alcohólico',
  Available: 'Disponible',
  Description: 'Descripción',
  simple: 'Simple',
  search_product: 'Buscar producto...',
  min_price: 'Precio mínimo',
  max_price: 'Precio máximo',
  add_product: 'Agregar producto',
  edit_product: 'Editar producto',
  product_details: 'Detalles del producto',
  composition_items: 'Ítems de composición',
  caption: 'Subtítulo',
  extra: 'Extra',
  max_quantity: 'Cantidad máxima',
  add_composition: 'Agregar composición',
  items: 'Artículos',
  item_name: 'Nombre del artículo',
  add_item: 'Agregar artículo',
  preparation_time: 'Tiempo de preparación',
  Orders: 'Pedidos',
  current_orders: 'Órdenes Actuales',
  store: 'Restaurante',
  no_stores_message: 'No tienes restaurantes para ver órdenes, por favor crea una primero',
  Pending: 'Pendiente',
  in_preparation: 'En Preparación',
  Preparation: 'Preparación',
  accept_order: 'Aceptar pedido',
  order_is_ready: 'El pedido está listo',
  finish_order: 'Finalizar pedido',
  total: 'Total',
  order_of: 'Pedido de',
  Contact: 'Contacto',
  reservations_shares: 'Compartir reservas',
  client_name: 'Nombre del cliente',
  total_amount: 'Cantidad total',
  owner_share: 'Participación del propietario',
  instadrink_share: 'Participación de I.D',
  rp_share: 'Participación de PromoBooster',
  thanks_join_invite: 'Gracias por unirte a nuestra comunidad',
  ambassador_share: 'Participación del embajador',
  shares_distribution: 'Distribución de acciones',
  group_name: 'Nombre del grupo',
  group_members: 'Miembros del grupo',
  created_by: 'Creado por',
  leave_group: 'Abandonar el grupo',
  with_product: 'Con producto',
  all_categories: 'Todas las categorías',
  inclusives: 'Incluyentes',
  closing_Time: 'Hora de cierre',
  open: 'Abierto',
  Rush_hour: 'Hora pico',
  preview_store: 'Vista previa del restaurante',
  Delete_account: 'Eliminar cuenta',
  Are_you_sure_you_want_to_delete_your_account: '¿Estás seguro de que deseas eliminar tu cuenta',
  delete_account_page_message:
    'Al elegir eliminar tu cuenta, estás optando por la eliminación permanente de todos tus datos de nuestro sistema.',
  restaurants: 'Restaurantes',
  stores: 'Tiendas',
  network: 'Red',
  commandes: 'Pedidos',
  applications: 'Aplicaciones',
  inviteUsers: 'Invitar usuarios',
  inviteAgents: 'Invitar agentes',
  inviteStaff: 'Invitar personal',
  invitationsList: 'Lista de invitaciones',
  promotersTree: 'Árbol de invitaciones',
  promotionGroups: 'Grupos de PromoBoosters',
  onboarding: 'Onboarding',
  settings: 'Configuraciones',
  account_created_check_email:
    'Cuenta creada con exito. Verifica tu correo para completar la activación',
  verify_before_expire: 'Verificar antes de expirar',
  if_mistake_contact_instadrink: 'Si hay un error, ponte en contacto con I.D',
  invitation_rejected: 'Invitación rechazada',
  invite_owner: 'Quiero invitar a un propietario',
  invite_referrer: 'Quiero invitar a un PromoBooster',
  invite_ambassador: 'Quiero invitar a un embajador',
  invite_more_users: 'Invitar otros usuarios',
  your_organisation_staff: 'Tu personal de la organización',
  inv_org_desc: 'El organizador de tu evento',
  inv_bnc_desc: 'El controlador de acceso de tu evento',
  invite_more_staff: 'Invitar otros personal',
  since: 'desde',
  delete_event_modal: '¿Estás seguro de que quieres eliminar el evento',
  publish_event_modal: '¿Estás seguro de que quieres publicar el evento',
  cancel_event_modal: '¿Estás seguro de que quieres cancelar el evento',
  archive_event_modal: '¿Estás seguro de que quieres archivar el evento',
  duplicate_event: 'Duplicar evento',
  duplicate: 'Duplicar',
  reservations: 'Reservas',
  multiple_persons: 'Varias personas',
  persons_number: 'Número de personas',
  vatRate: 'Tipo de IVA',
  price_TTC: 'Precio con impuestos',
  Occupation: 'Ocupación',
  priceHt: 'Precio sin IVA',
  promobooster_mode: 'Modo Promobooster',
  promo_mode: 'Modo Promo',
  Amount: 'Cantidad',
  Percentage: 'Porcentaje',
  include_owners: 'Incluir dueñas',
  add_anonymous_promoter: 'Agregar SpyBooster', 
  Confirm_Name: 'Confirmar nombre',
  Add_Anonymous_Name: 'Agregar SpyBooster',
  ask_for_invoice: 'pedir factura', 
  add_invoice_button: 'agregar  ',
  enter_valid_invoice: 'Por favor introduce una factura válida',  
  Compan_Name_Last_Name :' Empresa/Nombre/Apellido',
  tva :'TVA',
  adresse :'dirección',
  postal_code :'Código Postal', 
  country :'País',
  additional_information :'Información adicional', 
  invoice_information :'Facturación',
  survey_information :'Encuesta',
  know_from_invoice :'¿Qué información requieres de tu factura?',
  know_from_attendees :'¿Qué necesitas saber de tus asistentes?',
  add_invoice: 'Agregar nuevo campo',
  active_invoice: 'Activar la factura',
  actived_invoice: 'La factura está activada', 
  preview_your_share: 'Vista Previa Distribución ',
  Number_of_event: 'Número de evento', 
  time_of_creation: 'Crear en', 
  Owner_details: 'Detalles del propietario', 
  greetings: 'Saludos,',
  you_have_been_invited: 'Has sido invitado a unirte a I.D 🎉',
  plz_wait_a_moment: 'Por favor, espera un momento',
  thanks_time: 'Tiempo de agradecimientos',
  averagePreparationTime: 'Tiempo medio de preparación',
  minutes: 'minutos',
  seconds: 'segundos',
  select_menu: 'Seleccionar un menú',
  capacity_warning_message: 'Las capacidades están agotadas en comparación con la capacidad total.',
  add_product_alert:
    'Para añadir un producto a tus entradas, por favor vincula una tienda a tu evento',
  remaining_places: 'lugares restantes',
  navigate_to_event_menu: 'Ir al menú del evento',
  you_have_no_store: 'No tienes un restaurante',
  reject_order: 'Rechazar orden',
  add_Waiter: 'Añadir camarero',
  add_room: "Añadir Habitación",
  room_name: "Nombre De Habitación",
  "campaigns": {
    "title": "Campañas de correo",
    segment: "Segmento",
    "audience": "Audiencia",
    "createNew": "Crear nueva campaña",
    "name": "Nombre",
    "template": "Plantilla",
    "scheduledTime": "Hora programada",
    "status": "Estado",
    "actions": "Acciones",
    "backToList": "Volver a la lista de campañas",
    "info": "Información de la campaña",
    "mails": "Correos",
    "email": "Correo electrónico",
    "emailStatus": "Estado del correo",
    "confirmDeletion": "Confirmar eliminación",
    "deleteConfirmationMessage": "¿Estás seguro de que quieres eliminar esta campaña de correo? Esta acción no se puede deshacer.",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "edit": "Editar campaña",
    "create": "Crear nueva campaña",
    "campaignName": "Nombre de la campaña",
    "save": "Guardar",
    "selectTemplate": "Seleccionar una plantilla",
    "createNewTemplate": "Crear nueva plantilla",
    "audienceType": {
      "title": "Audiencia",
      "all": "Todos los contactos",
      "segment": "Segmento específico",
      "eventBuyers": "Compradores de entradas"
    },
    "drawer": {
      "create": "Crear Campaña",
      "edit": "Editar Campaña",
      "name": "Nombre de la Campaña",
      "audienceType": "Tipo de Audiencia",
      "segment": "Seleccionar Segmento",
      "draftInfo": "La campaña se guardará como borrador"
    },
    "campaignStatus": {
      "pending": "Pendiente",
      "scheduled": "Programado",
      "sending": "Enviando",
      "launched": "Enviado",
      "failed": "Fallido",
      "template-ready": "Plantilla listo"
    },
    "emailstatus": {
      "email.sent": "Enviado",
      "email.delivered": "Entregado",
      "email.delivery_delayed": "Entrega Retrasada",
      "email.complained": "Queja Recibida",
      "email.bounced": "Rebotado",
      "email.opened": "Abierto",
      "email.clicked": "Cliqueado",
      "email.unsubscribed": "Desuscrito",
      "email.failed": "Fallo de Envío",
      "email.blocked": "Bloqueado",
      "email.dropped": "Descartado",
      "email.deferred": "Diferido",
      "email.rejected": "Rechazado"
    },
    "stats": {
        "title": "Estadísticas de la Campaña",
        "delivered": "Entregados",
        "opened": "Abiertos",
        "clicked": "Clicados",
        "bounced": "Rebotados"
      },
    "subject": "Asunto del correo",
    "subjectHelperText": "Puede personalizar el asunto de la plantilla para esta campaña",
    "launchType": {
      "title": "Tipo de lanzamiento",
      "immediate": "Lanzar inmediatamente",
      "scheduled": "Programar para más tarde"
    },
    "schedule": {
      action: "Programar",
      send: "Enviar",
      "title": "Schedule Campaign",
      "info":  "Schedule sending for campaign: {{name}}",
      scheduledTimeHelper: "Elija una fecha y hora futura para enviar su campaña",
      "errors": {
        "scheduleTimeRequired": "Por favor seleccione una hora de programación",
        "testEmailRequired":"Por favor ingrese una dirección de email de prueba",
        "scheduleFailed":"Error al programar la campaña",
        "testSendFailed": "Error al enviar el email de prueba",
      },
      "success": {
        "testSent": "Email de prueba enviado con éxito",
        "launched":"Campaña lanzada con éxito",
        "scheduled": "Campaña programada con éxito"
      }
    }
  },
  "Include_Platform_Fees": "Incluir tarifas de plataforma",
  "Final_Price": "Precio final",
  "Platform_Fee_Info": "Cuando está activado, la comisión de la plataforma del 2,5% se añadirá al precio del billete y será pagada por los asistentes. Si está desactivado, esta comisión se deducirá de sus ingresos por venta de entradas.",
  Cost_to_buyers: "Coste a los compradores",
  "contacts": {
    "totalContacts": "{{count}} contactos",
    "import": "Importar contactos",
    "export": "Exportar a CSV",
    "deleteSelected": "Eliminar seleccionados",
    "search": "Buscar",
    "noContactsToExport": "No hay contactos para exportar.",
    "errorDeletingContacts": "Error al eliminar contactos",
    "errorLoadingContacts": "Error al cargar contactos",
    "confirmDeletion": "Confirmar eliminación",
    "confirmDeletionMessage": "¿Está seguro de que desea eliminar los {count} contactos seleccionados?",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "dragAndDropInstruction": "Arrastra y suelta un archivo CSV o XLSX aquí, o haz clic para seleccionar",
    "file": "Archivo: {name}",
    "removeFile": "Eliminar archivo",
    "informationMapping": "Mapeo de información",
    "select": "Seleccionar",
    "additionalFields": "Campos adicionales",
    "errorParsingFile": "Error al analizar el archivo. Por favor, inténtelo de nuevo.",
    "contactsImportedSuccessfully": "Contactos importados con éxito",
    "errorImportingContacts": "Error al importar contactos. Por favor, inténtelo de nuevo.",
    "fields": {
      "email": "Correo electrónico",
      "firstName": "Nombre",
      "lastName": "Apellido",
      "phone": "Teléfono",
      "age": "Edad",
      "gender": "Género",
      "country": "País",
      "zone": "Zona",
      "postalCode": "Código Postal"
    }
  },
  "no_confirmed_tickets_to_export": "No hay entradas confirmadas para exportar",
  "export_confirmed_ticket_ids_tooltip": "Exportar IDs de entradas confirmadas",
  "loading": "Cargando...",
  "export_confirmed_tickets": "Exportar Entradas Confirmadas ({{count}})",
  check_invitation_list: "Comprueba la lista de invitaciones",
  "templates": {
    "createNew": "Crear nueva plantilla",
    "subject": "Asunto del correo",
    "subjectRequired": "El asunto del correo es obligatorio",
    "createSuccess": "Plantilla creada exitosamente",
    "createError": "Error al crear la plantilla"
  },
  "segments": {
      "create": {
        "title": "Crear Nuevo Segmento",
        "selectType": "Seleccione un tipo de segmento",
        "dynamicSegment": {
          "title": "Segmento de Filtro Dinámico",
          "description": "Aplique una lista de atributos para seleccionar una parte de sus contactos. Sus contactos se agregarán o eliminarán automáticamente de este segmento según los filtros elegidos."
        },
        "manualSegment": {
          "title": "Segmento Manual vía CSV",
          "description": "Puede importar una lista de contactos desde un archivo CSV para agruparlos en un segmento. Puede usar esta función para volver a importar contactos existentes como un nuevo segmento."
        },
        "step": "Paso",
        "of": "de",
        removeFile: "Eliminar archivo",
        "newsletterContacts": "{{count}} / 2000 contactos suscritos al boletín ya importados.",
        "selectAgeRange": "Seleccione el rango de edad que desea filtrar:",
        "averageAge": "Promedio: {{age}} años",
        "infoKnownFor": "Esta información es conocida para {{count}} contactos",
        "selectCountries": "Seleccionar uno o más países",
        "selectZones": "Seleccionar zonas",
        "addPostalCodes": "Agregar códigos postales (separados por coma)",
        "selectLanguage": "Seleccionar idioma",
        "whenAdded": "Cuando este contacto fue añadido a tu comunidad",
        "contactsImported": "contactos suscritos a su boletín ya importados.",
        "dropFileHere": "Suelte su archivo CSV, XLS o XLSX aquí",
        "fileRequirements": "Su archivo debe tener al menos una columna con la dirección de correo electrónico de los contactos. Y para enviarles boletines, debe tener una columna de suscripción al boletín (sí/no).",
        "removeFilters": "Eliminar filtros",
        "contactInformation": "INFORMACIÓN DE CONTACTO",
        "marketing": "MARKETING",
        "history": "HISTORIAL",
        "fieldMapping": "Mapeo de Campos",
        "additionalFields": "Campos Adicionales",
        "segmentName": "Nombre del Segmento",
        "description": "Descripción",
        "matchingContacts": "contactos coincidentes",
        "back": "Atrás",
        "cancel": "Cancelar",
        "next": "Siguiente",
        "createSegment": "Crear Segmento",
        "importContacts": "Importar contactos como segmento"
      },
      "fieldsMapping": {
          "name": "Nombre",
          "email": "Correo",
          "firstName": "Nombre",
          "lastName": "Apellido",
          "phone": "Teléfono",
          "age": "Edad",
          "gender": "Género",
          "country": "País",
          "zone": "Zona",
          "postalCode": "Código postal"
        },
      "filters": {
        activeFilters: "Filtros activos",
        "hasEmail": "Tiene un correo electrónico",
        "emailVerified": "Correo electrónico verificado",
        "hasPhone": "Tiene un número de teléfono",
        "age": "Edad",
        "gender": "Género",
        "location": "Ubicación",
        "language": "Idioma",
        "dateAdded": "Fecha de Agregado",
        "selectCountries": "Seleccione uno o más países",
        "selectZones": "Seleccione zonas",
        "postalCodes": "Agregar códigos postales (separados por comas)",
        "selectLanguage": "Seleccionar idioma",
        "allTime": "Todo el tiempo",
        "today": "Hoy",
        "yesterday": "Ayer",
        "last7days": "Últimos 7 días",
        "last14days": "Últimos 14 días",
        "last30days": "Últimos 30 días",
        "last90days": "Últimos 90 días",
        "customDates": "Fechas personalizadas",
        "from": "Desde",
        "to": "Hasta"
      }
    },
    "segment": {
      "Create": {
        "matchingContacts": "contactos coincidentes",
        "back": "Atrás",
        "cancel": "Cancelar",
        "next": "Siguiente",
        "importContacts": "Importar contactos",
        "title": "Crear segmento"
      }
    },
    "stripe_connect": {
        "setup_stripe_account": "Configurar Stripe",
        "complete_stripe_setup": "Completar Configuración",
        "alert_stripe_incomplete_setup": "La configuración de tu cuenta Stripe está incompleta. Por favor, completa el proceso de verificación para comenzar a recibir pagos por tus eventos.",
        "error_creating_stripe_account": "Error al crear la cuenta Stripe. Por favor, inténtalo de nuevo más tarde.",
        "error_creating_stripe_link": "Error al generar el enlace de configuración de Stripe. Por favor, inténtalo de nuevo más tarde.",
        "legal_verification": "Verificación Legal Requerida",
        "alert_stripe_configuration_title": "Para recibir pagos por tus eventos, necesitas configurar tu cuenta Stripe Connect. Este paso es obligatorio para el cumplimiento legal y el procesamiento seguro de pagos."
      },
      "survey": {
    "title": "Creador de Encuestas de Eventos",
    "enable": "Habilitar Encuesta",
    "noResponses": "Aún no hay respuestas",
    "totalResponses": "Respuestas totales: {{count}}",
    "noSurveyData": "No hay datos de encuesta disponibles",
    "errorLoading": "Error al cargar los datos de la encuesta",
    "question": {
      "title": "Pregunta {{number}}",
      "required": "Obligatorio",
      "text": "Texto de la Pregunta",
      "type": "Tipo de Pregunta",
      "types": {
        "shortText": "Texto Corto",
        "longText": "Texto Largo",
        "radio": "Botones de Radio",
        "checkbox": "Casillas de Verificación"
      },
      "options": "Opciones:",
      "addOption": "Añadir Opción",
      "submit": "Enviar Pregunta",
      "validation": {
        "emptyText": "El texto de la pregunta no puede estar vacío.",
        "radioOptions": "La pregunta de opción única debe tener al menos una opción.",
        "checkboxOptions": "La pregunta de opción múltiple debe tener al menos una opción."
      }
    },
    "addQuestion": "Añadir Pregunta",
    "answersSummary": "Resumen de Respuestas"
  },
  promoboosters: {
    "referral_sales": "Ventas por Referencia",
    "other_sales": "Otras Ventas",
    "available": "Disponible",
    "No_tickets_sold_yet": "Aún no se han vendido entradas",
    "ticket_category": "Categoría de Entrada",
    "total_sold": "Total Vendido",
    "initial_quantity": "Cantidad Inicial"
  },
      guests: {
        guestLists: 'Listas de invitados',
        addList: 'Añadir lista',
        addGuest: 'Añadir invitado',
        searchGuests: 'Buscar invitados',
        addNewList: 'Añadir nueva lista',
        listName: 'Nombre de la lista',
        guestList: 'Lista de invitados',
        sendEmailsToNewGuests: 'Enviar correos a nuevos invitados',
        single: 'Individual',
        import: 'Importar',
        text: 'Texto',
        name: 'Nombre',
        email: 'Correo',
        totalTickets: 'Entradas totales',
        freeTickets: 'Entradas gratuitas',
        dragDropCsv: 'Arrastra y suelta un archivo CSV aquí, o haz clic para seleccionar un archivo',
        guestDetailsPlaceholder: 'Ingresa detalles de invitados (uno por línea): Nombre, EntradasTotales+EntradasGratuitas, Correo',
        importGuests: 'Importar invitados',
        editGuest: 'Editar invitado',
        update: 'Actualizar',
        delete: 'Eliminar',
        confirmDelete: 'Confirmar eliminación',
        confirmDeleteMessage: '¿Estás seguro de que quieres eliminar este invitado? Esta acción no se puede deshacer.',
        cancel: 'Cancelar',
        mapColumns: 'Mapear columnas',
        emailLabel: 'Correo',
        ticketsInfo: 'Entradas',
        "deleteList": "Eliminar Lista de Invitados",
        "deleteListConfirmation": "¿Está seguro que desea eliminar la lista de invitados: ",
        "deleteListWarning": " Esto también eliminará todos los invitados en esta lista y no se puede deshacer.",
        "invalidFileType": "Tipo de archivo no válido. Por favor, suba un archivo CSV o Excel (.csv, .xlsx, .xls)",
        "csvImportInstructions": "Suba un archivo CSV o Excel con la información de los invitados. El archivo debe contener las siguientes columnas:",
    "csvRequiredColumns": "Columnas requeridas",
    "nameColumnDescription": "Nombre completo del invitado (obligatorio)",
    "emailColumnDescription": "Dirección de correo electrónico del invitado (opcional)",
    "totalTicketsColumnDescription": "Número total de entradas asignadas (mínimo 1)",
    "freeTicketsColumnDescription": "Número de entradas gratuitas (debe ser menor o igual al total de entradas)"
      },
   "confirm_refund": "Confirmar reembolso",
    "refund_confirmation_message": "¿Estás seguro de que quieres reembolsar este boleto? Esta acción no se puede deshacer.",
    "refund_amount": "Cantidad a reembolsar",
    "processing": "Procesando...",
    "only_confirmed_tickets_can_be_refunded": "Solo se pueden reembolsar los boletos confirmados",
    "refund_ticket": "Reembolsar boleto",
    'Remove Deformation': 'Remove Deformation',
  'Deformed Price': 'Deformed Price',
  'Final Price': 'Final Price',
  'No deformations available': 'No price deformations available',
  'Price deformation added': 'Price deformation added successfully',
  'Price deformation removed': 'Price deformation removed',
  'Price Deformations Management': 'Price Deformations Management',
  'Add Price Deformation': 'Add Price Deformation',
  'No price deformations defined yet': 'No price deformations defined yet',
  'Inactive': 'Inactive',
  'off': 'off',
  'Priority': 'Priority',
  'Domains': 'Domains',
  'Are you sure you want to delete this price deformation?': 'Are you sure you want to delete this price deformation?',
  'Price deformation deleted successfully': 'Price deformation deleted successfully',
  'Failed to delete price deformation': 'Failed to delete price deformation',
  "Ticket Restrictions": "Ticket Restrictions",
    "Add Access Restriction": "Add Access Restriction",
    "Access Restriction": "Access Restriction",
    "Allowed Domains": "Allowed Domains",
    "Restriction Rules": "Restriction Rules",
    "A ticket can have either multiple price deformations OR one access restriction, but not both.": "A ticket can have either multiple price deformations OR one access restriction, but not both.",
    "Adding an access restriction will remove all price deformations. Continue?": "Adding an access restriction will remove all price deformations. Continue?",
    "Access_Restriction_Description": "El acceso a boletos puede estar limitado según tu dominio de correo electrónico.",
    total_tickets_sold: 'Entradas vendidas',
    total_revenue: 'Ingresos totales',
    eventTicketStatistics: 'Estadísticas de entradas del evento',
    eventCapacity: 'Capacidad del evento',
    totalTickets: 'Total de entradas',
    totalReservations: 'Total de reservas',
    ticketName: 'Nombre de la entrada',
    initialQuantity: 'Cantidad inicial',
    soldQuantity: 'Cantidad vendida',
    noTicketData: 'No hay datos de entradas disponibles',
    errorLoadingTickets: 'Error al cargar los datos de las entradas',
    na: 'N/D',
    "confirm_send_email": "Confirmar envío de correo",
    "send_email_confirmation_message": "¿Está seguro de que desea enviar el correo de confirmación a este destinatario?",
    "recipient": "Destinatario",
    "sending": "Enviando...",
    "send": "Enviar",
    "ticketInvitation": {
    "import": {
      "steps": {
        "setup": "Configuración",
        "preview": "Vista previa e Importar"
      },
      "next": "Siguiente",
      "back": "Atrás",
      "summary": "Resumen de importación",
      "totalRecords": "Total de registros",
      "selectedTicket": "Categoría de entrada seleccionada",
      "title": "Importar invitaciones",
      "progress": "Procesando {{current}}/{{total}} ({{success}} exitosos, {{failed}} fallidos)",
      "dragOrSelect": "Suelta tu archivo CSV aquí o haz clic para seleccionar",
      "dropHere": "Suelta el archivo CSV aquí",
      "mapColumns": "Mapear columnas CSV",
      "previewTitle": "Vista previa",
      "records": "registros",
      "firstThreeRecords": "Primeros tres registros",
      "importButton": "Importar {{count}} invitaciones",
      "errors": {
        "unknown": "Ocurrió un error desconocido",
        "csvEmpty": "El archivo CSV está vacío",
        "invalidFormat": "Formato CSV inválido",
        "parseError": "Error al analizar el archivo CSV",
        "fileTooLarge": "Archivo demasiado grande (máx 5MB)",
        "invalidFileType": "Solo se permiten archivos CSV",
        "invalidDataRows": "{{count}} filas contienen datos inválidos",
        "missingMapping": "Por favor, mapea todas las columnas requeridas",
        "creationFailed": "Error al crear invitaciones"
      },
      "success": {
        "partial": "Importación completada: {{success}} exitosos, {{failed}} fallidos.\nDetalles:\n{{details}}",
        "complete": "{{count}} invitaciones creadas exitosamente"
      },
      "explanation": {
        "main": "Importe entradas para múltiples asistentes usando un archivo CSV. Perfecto para migrar desde otros sistemas de venta de entradas o crear entradas para una lista predefinida de invitados.",
        "features": "Esta herramienta de importación:",
        "feature1": "Creará entradas digitales con códigos QR únicos para cada asistente",
        "feature2": "Enviará correos electrónicos de confirmación a los asistentes con sus códigos QR",
        "feature3": "Permitirá asignar categorías específicas de entradas a todos los invitados importados"
      }
    }
  },
  "tickets": {
    price_per_ticket_ht: "por entrada sin IVA",
    "hide_description": "Ocultar entrada (solo visible en la página secreta)",
    "validation": {
      "titleRequired": "El título es obligatorio",
      "descriptionRequired": "La descripción es obligatoria",
      "priceZero": "El precio no puede ser cero",
      "priceRequired": "El precio es obligatorio",
      "initialQuantityZero": "La cantidad inicial no puede ser cero",
      "maxQuantityLimit": "La cantidad máxima debe ser menor que la cantidad inicial",
      "maxQuantityZero": "La cantidad máxima no puede ser cero",
      "minQuantityLimit": "La cantidad mínima debe ser menor que la cantidad máxima e inicial",
      "minQuantityZero": "La cantidad mínima no puede ser cero",
      "categoryRequired": "La categoría es obligatoria",
      "productRequired": "El producto es obligatorio",
      "quantityRequired": "La cantidad es obligatoria",
      "quantityGreaterThanZero": "La cantidad debe ser mayor que cero",
      "withProductRequired": "El producto es obligatorio cuando iscomposite es verdadero",
      "fieldRequired": "Este campo es obligatorio",
      "vatRateRange": "La tasa de IVA debe estar entre 1 y 100"
    }
  },
  "price_deformation": {
      "name_required": "El nombre es obligatorio",
      "deformation_type_required": "El tipo de deformación es obligatorio",
      "value_required": "El valor es obligatorio",
      "value_positive": "El valor debe ser positivo",
      "invalid_value": "Valor inválido",
      "domain_required": "Se requiere al menos un dominio",
      "edit_price": "Editar deformación de precio",
      "new_price": "Nueva deformación de precio",
      "edit_access": "Editar restricción de acceso",
      "new_access": "Nueva restricción de acceso",
      "name": "Nombre",
      "description": "Descripción",
      "active": "Activo",
      "deformation_type": "Tipo de deformación",
      "percentage": "Porcentaje",
      "fixed_amount": "Cantidad fija",
      "deformation_value": "Valor de deformación",
      "email_domains": "Dominios de correo",
      "add_domain": "Añadir dominio",
      "priority": "Prioridad",
      "cancel": "Cancelar",
      "update": "Actualizar",
      "create": "Crear",
      "priority_info": "La prioridad determina el orden en que se aplican las restricciones. Las restricciones de mayor prioridad (número más alto) se verifican primero. Por ejemplo, una restricción con prioridad 2 se verificará antes que la prioridad 1."
    }
  
};

export default es;
