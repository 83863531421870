import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import eventModalReducer from './slices/eventModal';
import storeReducer from './slices/store';
import { EventsApi } from './api/events.api';
import { TicketsApi } from './api/tickets.api';
import { UsersApi } from './api/users.api';
import { SurveysApi } from './api/surveys.api';
import { ContactsApi } from './api/contacts.api';
import { AdminApi } from './api/admin.api';
import { EventCategoriesApi } from './api/eventCatygory.api';
import { MenusApi } from './api/menus.api';
import { ReservationsApi } from './api/reservations.api';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

console.log(EventsApi.reducerPath);
console.log(ReservationsApi.reducerPath);
console.log(SurveysApi.reducerPath);

const rootReducer = combineReducers({
  [EventsApi.reducerPath]: EventsApi.reducer,
  [UsersApi.reducerPath]: UsersApi.reducer,
  [AdminApi.reducerPath]: AdminApi.reducer,
  [EventCategoriesApi.reducerPath]: EventCategoriesApi.reducer,
  [MenusApi.reducerPath]: MenusApi.reducer,
  [SurveysApi.reducerPath]: SurveysApi.reducer,
  [ContactsApi.reducerPath]: ContactsApi.reducer,
  [ReservationsApi.reducerPath]: ReservationsApi.reducer,
  [TicketsApi.reducerPath]: TicketsApi.reducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  eventModal: eventModalReducer,
  kanban: kanbanReducer,
  store: storeReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
